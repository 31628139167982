<template>
  <div id="app">
    <Greeting
      v-if="stage === 'greeting'"
      @beginRegister="onBeginRegister"
      @downloadResults="onResultsDownload"
    />
    <Register v-if="stage === 'register'" @startTest="onTestStart" />
    <Test v-if="stage === 'test'" @endTest="onTestEnd" />
    <Result
      v-if="stage === 'result'"
      @restartTest="onTestRestart"
      :composition="composition"
      :semantic="semantic"
      :details="details"
      :duration="duration"
      :success="success"
    />
  </div>
</template>

<script>
import Greeting from './components/Greeting.vue'
import Register from './components/Register.vue'
import Test from './components/Test.vue'
import Result from './components/Result.vue'

import axios from 'axios'

export default {
  name: 'App',
  components: {
    Greeting,
    Register,
    Test,
    Result
  },
  data () {
    return {
      stage: 'greeting',
      name: '',
      city: '',
      role: '',
      competence: '',
      composition: 0,
      semantic: 0,
      details: 0,
      duration: 0,
      success: true
    }
  },
  methods: {
    onTestEnd (result) {
      this.success = result.success
      this.stage = 'result'
      this.closeFullscreen()
      if (result.success) {
        this.composition = result.composition
        this.semantic = result.semantic
        this.details = result.details
        this.duration = this.millisToHMS(result.duration)

        axios.post('https://suitability.cgischool.ua/api.php', {
          name: this.name,
          city: this.city,
          role: this.role,
          competence: this.competence,
          composition: this.composition,
          semantic: this.semantic,
          details: this.details,
          result: this.composition - this.details,
          duration: this.duration
        }).then(() => {
          axios.post('/sendmail.php', {
            to: 'bootcamp@cgischool.ua',
            subject: 'Suitability test report',
            from: 'test@cgischool.ua',
            fromName: 'CGI School Test Hub',
            message: `${this.name} з міста ${this.city} завершив(ла) тест на відповідність компетенції, набравши ${this.composition - this.details} балів.`
          })
        })
      }
    },
    onResultsDownload (password) {
      axios.post('/download.php', { password })
        .then(response => {
          if (response.data.status === 'success') {
            window.location = response.data.filename
          }
        })
        // .catch(function (error) {
        // })
    },
    onBeginRegister () {
      this.stage = 'register'
    },
    onTestStart (payload) {
      this.stage = 'test'
      this.name = payload.name
      this.city = payload.city
      this.role = payload.role
      this.competence = payload.competence
      this.openFullscreen()
    },
    onTestRestart () {
      this.stage = 'greeting'
      this.name = ''
      this.composition = 0
      this.semantic = 0
      this.details = 0
      this.duration = 0
      this.success = true
    },
    openFullscreen () {
      const elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen()
      }
    },
    millisToHMS (duration) {
      let seconds = Math.floor((duration / 1000) % 60)
      let minutes = Math.floor((duration / (1000 * 60)) % 60)
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

      hours = (hours < 10) ? '0' + hours : hours
      minutes = (minutes < 10) ? '0' + minutes : minutes
      seconds = (seconds < 10) ? '0' + seconds : seconds

      return hours + ':' + minutes + ':' + seconds
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 480px) {
  html {
    font-size: 0.85rem;
  }
}
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
