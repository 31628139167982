<template>
  <div id="test">
    <div id="option-1" class="option">
      <OptionalImage
        :src="'/images/' + this.currentPair[randomBool].image"
        @pick="pickOption(currentPair[randomBool].group)"
      />
    </div>
    <div id="option-2" class="option">
      <OptionalImage
        :src="'/images/' + this.currentPair[randomBool2].image"
        @pick="pickOption(currentPair[randomBool2].group)"
      />
    </div>
    <div class="progress-bar">
      <div
        id="progress-time"
        :class="['progress-state', { hurry: hurry && timeCount > 5000 }]"
        :style="{ width: progressTimeWidth + '%' }"
      />
    </div>
    <div class="progress-bar">
      <div
        id="progress-test"
        class="progress-state"
        :style="{ width: progressWidth + '%' }"
      />
    </div>
  </div>
</template>

<script>
import OptionalImage from '@/components/OptionalImage.vue'

export default {
  components: {
    OptionalImage
  },
  name: 'Test',
  data () {
    return {
      allImages: {
        group1: [
          'image_1_001.jpg',
          'image_1_002.jpg',
          'image_1_003.jpg',
          'image_1_004.jpg',
          'image_1_005.jpg',
          'image_1_006.jpg',
          'image_1_007.jpg',
          'image_1_008.jpg',
          'image_1_009.jpg',
          'image_1_010.jpg',
          'image_1_011.jpg',
          'image_1_012.jpg',
          'image_1_013.jpg',
          'image_1_014.jpg',
          'image_1_015.jpg',
          'image_1_016.jpg',
          'image_1_017.jpg'
        ],
        group2: [
          'image_2_001.jpg',
          'image_2_002.jpg',
          'image_2_003.jpg',
          'image_2_004.jpg',
          'image_2_005.jpg',
          'image_2_006.jpg',
          'image_2_007.jpg',
          'image_2_008.jpg',
          'image_2_009.jpg',
          'image_2_010.jpg',
          'image_2_011.jpg',
          'image_2_012.jpg',
          'image_2_013.jpg',
          'image_2_014.jpg',
          'image_2_015.jpg',
          'image_2_016.jpg',
          'image_2_017.jpg',
          'image_2_018.jpg',
          'image_2_019.jpg',
          'image_2_020.jpg',
          'image_2_021.jpg',
          'image_2_022.jpg',
          'image_2_023.jpg',
          'image_2_024.jpg',
          'image_2_025.jpg',
          'image_2_026.jpg',
          'image_2_027.jpg',
          'image_2_028.jpg',
          'image_2_029.jpg',
          'image_2_030.jpg',
          'image_2_031.jpg',
          'image_2_032.jpg',
          'image_2_033.jpg',
          'image_2_034.jpg',
          'image_2_035.jpg',
          'image_2_036.jpg',
          'image_2_037.jpg'
        ],
        group3: [
          'image_3_001.jpg',
          'image_3_002.jpg',
          'image_3_003.jpg',
          'image_3_004.jpg',
          'image_3_005.jpg',
          'image_3_006.jpg',
          'image_3_007.jpg',
          'image_3_008.jpg',
          'image_3_009.jpg',
          'image_3_010.jpg',
          'image_3_011.jpg',
          'image_3_012.jpg',
          'image_3_013.jpg',
          'image_3_014.jpg',
          'image_3_015.jpg',
          'image_3_016.jpg',
          'image_3_017.jpg',
          'image_3_018.jpg',
          'image_3_019.jpg',
          'image_3_020.jpg',
          'image_3_021.jpg',
          'image_3_022.jpg',
          'image_3_023.jpg',
          'image_3_024.jpg',
          'image_3_025.jpg',
          'image_3_026.jpg',
          'image_3_027.jpg',
          'image_3_028.jpg',
          'image_3_029.jpg',
          'image_3_030.jpg',
          'image_3_031.jpg',
          'image_3_032.jpg',
          'image_3_033.jpg',
          'image_3_034.jpg',
          'image_3_035.jpg',
          'image_3_036.jpg',
          'image_3_037.jpg',
          'image_3_038.jpg',
          'image_3_039.jpg',
          'image_3_040.jpg',
          'image_3_041.jpg',
          'image_3_042.jpg',
          'image_3_043.jpg',
          'image_3_044.jpg',
          'image_3_045.jpg',
          'image_3_046.jpg',
          'image_3_047.jpg',
          'image_3_048.jpg',
          'image_3_049.jpg',
          'image_3_050.jpg'
        ],
        group4: [
          'image_4_001.jpg',
          'image_4_002.jpg',
          'image_4_003.jpg',
          'image_4_004.jpg',
          'image_4_005.jpg',
          'image_4_006.jpg',
          'image_4_007.jpg',
          'image_4_008.jpg',
          'image_4_009.jpg',
          'image_4_010.jpg',
          'image_4_011.jpg',
          'image_4_012.jpg',
          'image_4_013.jpg',
          'image_4_014.jpg',
          'image_4_015.jpg',
          'image_4_016.jpg',
          'image_4_017.jpg',
          'image_4_018.jpg',
          'image_4_019.jpg',
          'image_4_020.jpg',
          'image_4_021.jpg'
        ]
      },
      poolOne: [],
      poolTwo: [],
      pairs: [],
      currentPairIndex: 0,
      currentPairUrls: [],
      currentPairImages: [],
      resultData: {
        composition: 0,
        semantic: 0,
        details: 0
      },
      randomBool: Math.round(Math.random()),
      loaded: false,
      start: new Date(),
      totalDuration: 5 * 60 * 1000,
      timeCount: 0,
      intervalId: 0
    }
  },
  computed: {
    randomBool2 () {
      return this.randomBool ? 0 : 1
    },
    pairsCount () {
      return this.pairs.length
    },
    currentPair () {
      return this.pairs[this.currentPairIndex]
    },
    progressWidth () { return 100 * this.currentPairIndex / this.pairsCount },
    progressTimeWidth () { return 100 * this.timeCount / this.totalDuration },
    hurry () { return this.progressWidth < this.progressTimeWidth }
  },
  methods: {
    pickOption (group) {
      switch (group) {
        case 1:
          this.resultData.composition++
          break
        case 2:
          this.resultData.composition++
          this.resultData.semantic++
          break
        case 3:
          this.resultData.details++
          break
        case 4:
          this.resultData.semantic++
          this.resultData.details++
          break
      }
      if (this.currentPairIndex < this.pairsCount - 1) { this.currentPairIndex++ } else {
        this.fanlizeTest()
      }
      this.randomBool = Math.round(Math.random())
    },
    shuffle (a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]]
      }
      return a
    },
    loadImage (url) {
      return new Promise(resolve => {
        const image = new Image()
        image.addEventListener('load', () => {
          resolve(image)
        })
        image.src = url
      })
    },
    fanlizeTest (success = true) {
      const duration = new Date() - this.start
      this.$emit('endTest', { ...this.resultData, duration, success })
    }
  },
  created () {
    this.allImages.group1.map(filename => {
      this.poolOne.push({ image: filename, group: 1 })
    })
    this.allImages.group2.map(filename => {
      this.poolOne.push({ image: filename, group: 2 })
    })
    this.allImages.group3.map(filename => {
      this.poolTwo.push({ image: filename, group: 3 })
    })
    this.allImages.group4.map(filename => {
      this.poolTwo.push({ image: filename, group: 4 })
    })
    this.shuffle(this.poolOne)
    this.shuffle(this.poolTwo)
    const maxPairsNum = Math.min(this.poolOne.length, this.poolTwo.length)
    for (let i = 0; i < maxPairsNum; i++) {
      this.pairs.push([
        this.poolOne[i],
        this.poolTwo[i]
      ])
    }
    this.intervalId = setInterval(() => {
      this.timeCount += 10
      if (this.timeCount > this.totalDuration) {
        clearInterval(this.intervalId)
        this.fanlizeTest(false)
      }
    }, 10)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#test {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #0b1015;
  position: relative;
  @media (max-aspect-ratio: 1/1) {
    display: block;
  }
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 6px);
  height: 100%;
  @media (max-aspect-ratio: 1/1) {
    height: calc(50% - 10px);
    width: 100%;
    &:first-child {
      margin-bottom: 12px;
    }
  }
}
.progress-bar {
  height: 3px;
  width: 100%;
  // overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.progress-state {
  height: 100%;
}
#progress-time {
  background: hsla(100, 100%, 70%, 0.9);
  transform: translateY(-100%);
  transition: height 0.5s ease-out;
  &.hurry {
    animation: hurry 1s infinite;
    height: 200%;
  }
}
#progress-test {
  background: hsla(0, 0%, 100%, 1);
  transition: width 500ms ease-in-out;
}
@keyframes hurry {
  0% {
    background: rgb(180, 0, 0);
  }
  50% {
    background: rgb(210, 110, 0);
  }
  100% {
    background: rgb(180, 0, 0);
  }
}
</style>
