<template>
  <form id="mentor" @submit="onDownload" v-if="isMentor">
    <input
      type="password"
      name="password"
      placeholder="Пароль"
      v-model="password"
      autocomplete="off"
      :class="{ error: error.password }"
    />
    <button type="submit">Завантажити результати</button>
    <a class="link" @click="onGoBack">Назад</a>
  </form>
  <form id="greeting" @submit="onSubmit" v-else>
    <h1>Це найпростіший тест.</h1>
    <p>
      Все, що тобі потрібно робити — це обирати зображення, які подобаються
      більше.<br />
      Без критеріїв.<br />
      На перший погляд.<br />
      Просто обирай те, що тобі до душі.<br /><br />
      У тебе буде 5 хвилин на понад 50 пар зображень, тож не зволікай.<br />
      Внизу екрану відображатимуться індикатори часу і прогресу виконання.
    </p>
    <button type="submit">Зрозуміло</button>
    <a class="link" @click="isMentor = true">Я лише спитати</a>
  </form>
</template>

<script>
export default {
  name: 'Greeting',
  data () {
    return {
      isMentor: false,
      password: '',
      error: {
        password: false
      },
      timeout: 0
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.$emit('beginRegister')
    },
    onDownload (event) {
      event.preventDefault()
      if (this.password === 'results2020') {
        this.$emit('downloadResults', this.password)
        this.password = ''
      } else {
        this.error.password = true
        if (this.timeout) { clearTimeout(this.timeout) }
        this.timeout = setTimeout(() => {
          this.error.password = false
        }, 3000)
      }
    },
    onGoBack () {
      this.isMentor = false
      this.password = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
form {
  font-size: 2rem;
  margin: 6vh auto;
  width: 86vw;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  min-height: 86vh;
  h1 {
    margin-top: 0;
    font-weight: 600;
    line-height: 1.15em;
    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }
  }
  p {
    font-size: 0.5em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 3em;
  }
  button {
    border: 0;
    padding: 0.5em 1.5em;
    background: #eee;
    display: inline-block;
    line-height: 1.5em;
    font-size: inherit;
    font-weight: 300;
    font-family: inherit;
    color: #363636;
    border-radius: 0;
    cursor: pointer;
    &:focus,
    &:hover {
      outline: none;
      background-color: #ccc;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
    }
  }
  input {
    font-size: inherit;
    width: 100%;
    font-weight: 300;
    font-family: inherit;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 2px solid #bdbdbd;
    margin-bottom: 1em;
    line-height: 1.75em;
    &:focus {
      outline: none;
      border-bottom-color: #363636;
    }
    &.error {
      border-color: rgb(190, 20, 0);
    }
    &::placeholder {
      color: #a3a3a3;
      // text-align: left;
    }
  }
  a.link {
    color: #777777;
    font-size: 0.5em;
    text-decoration: none;
    font-weight: 300;
    margin: 0 2em;
    align-self: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 2em;
    }
  }
}
</style>
